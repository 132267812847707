@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  height: 100%;
}
body {
  min-height: 100%;
  background-color: #fafafa;
  font-family: 'Exo', sans-serif;
}

#root {
  min-height: 100vh;
}

@layer components {
  .samnytt-input {
    @apply bg-gray-50 h-11 px-4 w-full border border-gray-200 rounded font-medium
     text-black text-base focus:border-gray-600 accent-transparent outline-none 
     placeholder:font-normal
    }
  .samnytt-input-label {
    @apply text-base font-normal text-gray-700
  }
  .samnytt-primary-btn {
    @apply h-11 w-full py-3 px-4 rounded font-semibold text-white bg-[#64bfb2] 
    hover:bg-[#4BB4A6] active:bg-[#39af9f] transition-all text-sm select-none 
    outline-none disabled:bg-gray-100 disabled:text-gray-300 disabled:cursor-not-allowed
  }
  .samnytt-secondary-btn {
    @apply h-11 w-full py-3 px-4 rounded text-gray-700 hover:text-gray-800
   bg-gray-100 hover:bg-gray-200 transition-all text-sm select-none outline-none
}
  .samnytt-card {
    @apply px-5 sm:px-8 py-4 sm:py-6 mb-5 mx-auto
    min-w-[15rem] max-w-md h-fit
     sm:w-1/2 sm:mx-4
    bg-white rounded-md 
    shadow-[0px_2px_8px_2px_#ededed]
  }
  .samnytt-card-headline {
    @apply mb-4 text-lg sm:text-xl font-bold text-gray-900 selection:bg-[#64bfb2]
     selection:text-white
  }
  .samnytt-svg-wrapper-for-btn {
    @apply flex justify-center items-center absolute h-8 w-8 mt-1.5 mr-1.5 rounded-full
     hover:bg-gray-100 top-0 right-0 transition
     /* TODO: remove absolute positioning from here */
  }
  .samnytt-svg-icon {
    @apply fill-gray-300 hover:fill-gray-400 transition
  }
}
/* .samnytt-secondary-btn */
